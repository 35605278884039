import { useEffect, useState } from 'react';
import { useQueryParams } from 'component/hooks/queryParams';
import cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { API_URL } from '../../../constants';
import { setAuthorizationHeaders } from 'api/axiosService';
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cssVar } from 'utils/css';
import LoadingContainer from 'component/partial/LoadingContainer';
import { useProfile } from 'api/profile';
import { useBillingSso } from 'api/auth';

const StyledContainer = styled(Container)({
  display: 'flex',
  minHeight: '100vh',
  paddingBottom: '1.50rem',
  paddingTop: '1.50rem',
  '& > *': {
    marginBottom: 'auto',
    marginTop: 'auto',
    width: '100%',
  },
});

export default function PostLogin() {
  const params = useQueryParams();
  const [loginError, setLoginError] = useState<boolean>(false);
  const code = params.get('code') ?? '';
  const errorParam = params.get('error') ?? '';
  const errorDescription = params.get('error_description') ?? '';
  const profile = useProfile(false);
  const { mutateAsync: getBillinglink } = useBillingSso();

  const { getAccessTokenSilently, error } = useAuth0();

  const navigate = useNavigate();
  const retUrl = window.localStorage.getItem('returnUrl');
  const { t } = useTranslation();
  window.localStorage.removeItem('returnUrl');

  useEffect(() => {
    async function setLogin() {
      try {
        if (code) {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: API_URL,
            },
          });
          setAuthorizationHeaders(token);
          cookie.set('user_session', token, {
            expires: 3560,
            sameSite: 'strict',
          });
        }

        if (Boolean(error) === false && Boolean(errorParam) === false) {
          const { data } = await profile.refetch();

          const client = data?.data.result?.client;

          if (client?.firstname === 'Rocket' && client?.lastname === 'Customer') {
            const { data: linkData } = await getBillinglink({
              destination: 'registration.php',
            });
            window.location.href = linkData.result?.link ?? '';
          } else {
            navigate(retUrl ?? '/dashboard');
          }
        }
      } catch (e) {
        setLoginError(true);
      }
    }
    setLogin();
  }, [
    code,
    error,
    errorParam,
    getAccessTokenSilently,
    getBillinglink,
    profile.refetch,
    navigate,
    retUrl,
  ]);

  if (loginError || error || (errorParam && errorDescription)) {
    return (
      <Box
        sx={{
          backgroundColor: cssVar('--color-shark'),
        }}
      >
        <StyledContainer maxWidth="sm">
          <Stack alignItems="center">
            <Box>
              <img src="/rocket.svg" alt="Rocket.net" />
            </Box>
            {errorDescription ? (
              errorDescription
            ) : (
              <Typography variant="subtitle1">{t('auth0LoginError')}</Typography>
            )}
          </Stack>
        </StyledContainer>
      </Box>
    );
  }
  return <LoadingContainer />;
}
